import React from "react";
import Img from "gatsby-image";

const About = ({ data }) => (
  <div className="about section" id="About">
    <div className="container">
      <div className="about-main row">
        <div className="left col-md-5 col-lg-4 mb-3">
          <Img
            fluid={data.photo.fluid}
            objectFit="cover"
            objectPosition="top center"
          />
        </div>
        <div className="left col-md-7 col-lg-8">
          <div className="about-details">
            <h2 className="sub-position">Über uns</h2>
            <p>
              Mit viel Herzblut führen Martina und Martin den Jakobsbrunnen
              mitten in der historischen Altstadt von Stein am Rhein. Alle
              kulinarischen Köstlichkeiten werden mit viel Liebe von Hand
              zubereitet.
            </p>
            <p>
              Sie ist die gute Seele, hat den Laden im Griff und vergisst
              nichts, NIE!
              <br />
              Er ist chaotisch, hat einen unbemerkten Weinfleck auf dem T-Shirt,
              aber sein Charme haut dich weg!
            </p>
            <p>
              Guter Sound läuft! IMMER!
              <br />
              Sie mag Musik, dreht aber oftmals das Volumen etwas herunter weil
              sie die Gäste nicht versteht.
              <br />
              Er mag Musik, dreht aber oftmals das Volumen etwas höher damit er
              die Gäste nicht versteht.
            </p>
            <p>
              Er bedient die Cimbali wie ein Profibarista und zaubert die besten
              Cafés der Stadt auf den Tisch.
              <br />
              Sie wird von ihm gerufen, wenn du Tee oder heisse Milch bestellst-
              das kann er nämlich nicht.
            </p>
            <p>
              Sie spricht fliessend französisch. Er weiss, dass Paris die
              Hauptstadt von Frankreich ist.
            </p>
            <p>
              Sie zaubert unwiderstehliche Kuchen und Torten aus dem Ofen, die
              süchtig machen.
              <br />
              Er trägt diese darum seit Jahren mit sich herum.
            </p>
            <p>
              Chillen können beide nicht, das wird schnell zu langweilig- es
              muss etwas los sein – IMMER.
            </p>
            <p>
              Wir teilen das Leben mit unseren zwei lustigen Mädchen - sie
              fordern uns heraus und machen unser Glück perfekt.
            </p>
            <p>
              Alle vier reisen leidenschaftlich gern, Fernweh haben wir immer.
              Nach der Reise ist vor der Reise!
            </p>
            <p>
              Und übrigens: etwas schräg sind nicht nur die Wände des Hauses,
              sondern auch wir!
            </p>
            <p>
              {" "}
              Sie liebt ihn. Er liebt sie.
              <br />
              Im Team sind sie gefährlich erfolgreich und unschlagbar!
            </p>
            <p>Auf bald, eure M&M</p>
            <h3>Öffnungszeiten</h3>
            <p><strong>Oktober und November geschlossen.</strong></p>
            <p>Donnerstag, Freitag und Samstag ab 14.00 Uhr.</p>
            <p>
              Gerne sind wir für private Anlässe auch ausserhalb der
              Öffnungszeiten für dich da. <a href="#Contact">Kontaktiere uns</a>
              .
            </p>
            <div>
              <h2 className="sub-postition">NEU</h2>
              <p>
                Ab Dezember sind schmucke Stücke von Schmückstück und Uno de 50
                sowie eine kleine Auswahl von Dekorationsartikeln im
                Jakobsbrunnen erhältlich.
              </p>
            </div>
            <div className="socials">
              <ul>
                <li>
                  <a
                    className="fab fa-facebook-f"
                    href={data.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  ></a>
                </li>
                <li>
                  <a
                    className="fab fa-instagram"
                    href={data.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default About;
