import React, { Component } from "react";

export default class Contact extends Component {
  render() {
    return (
      <div className="contact section" id="Contact">
        <div className="container">
          <div className="section-head">
            {" "}
            <h2 className="text-center">Kontakt</h2>{" "}
          </div>
          <div>
            <p>
              Jakobsbrunnnen
              <br />
              Understadt 25
              <br />
              CH-8260 Stein am Rhein
              <br />
              <a href="mailto:jb.steinamrhein@gmail.com">
                jb.steinamrhein@gmail.com
              </a>
              <br />
              <a href="tel:+41767478260">076 747 82 60</a>
              <br />
              Switzerland
            </p>
          </div>
          <div className="section-head">
            {" "}
            <h2 className="text-center">Öffnungszeiten</h2>{" "}
          </div>
          <div>
            <p><strong>Oktober und November geschlossen.</strong></p>
            <p>Donnerstag, Freitag und Samstag ab 14.00 Uhr.</p>
            <p>
              Gerne sind wir für private Anlässe auch ausserhalb der
              Öffnungszeiten für dich da.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
